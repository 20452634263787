import Axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import { store } from '../../index';
import { SetIsAuthAction } from '../../store/tokenReducer';

export class RestService {

  private axiosInstance: AxiosInstance;

  constructor() {
    this.init();
  }

  public init() {
    this.axiosInstance = Axios.create({
      baseURL: 'https://shop.handshome.it/wp-json/wc/v3/'
    });

    this.axiosInstance.interceptors.request.use(
      request => this.requestHandler(request)
    );
    this.axiosInstance.interceptors.response.use(
      response => this.responseHandler(response),
      respError => this.respErrorHandler(respError)
    );
  }

  public async get<T>(url: string, config?: AxiosRequestConfig, rawResponse: boolean = true): Promise<T> {
    const response: AxiosResponse<T> | AxiosError = await this.axiosInstance.get<T>(url, config);
    if ((response as any as AxiosError).isAxiosError) {
      throw response;
    }
    if (rawResponse) {
      return response as any;
    }
    return response.data;
  }

  public post<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T>(url, config);
  }

  public async put<T>(url: string, data?: any, config?: AxiosRequestConfig, rawResponse: boolean = true): Promise<T> {
    const response = await this.axiosInstance.put<T>(url, data, config);
    if ((response as any as AxiosError).isAxiosError) {
      throw response;
    }
    if (rawResponse) {
      return response as any;
    }
    return response.data;
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete<T>(url, config);
  }

  protected requestHandler(request: AxiosRequestConfig): AxiosRequestConfig {
    const httpToken = store.getState().httpToken;
    if (this.isRequestHandlerEnabled(request.params)) {
      request.headers = {
        // Authorization: 'Basic Y2tfOThmMzc5YTFlMjhjZmRlYTM3NDcwMDRhYzI1Nzc5MTQxYTRiMjU2NTpjc184YTBiMjY4YzMyZWNkYTE1YmUyZTkyYWMzNzVjNWI1OGVkZDE1NTdi'
        Authorization: httpToken
      };
      // mock calls
      if (request.url && request.url.indexOf('mock') !== -1) {
        request.baseURL = 'http://localhost:3000/wp-json/wc/v3/';
        request.url = request.url.replace('/mock', '');
      }
    }
    return request;
  }

  protected responseHandler(response: AxiosResponse): any {
    if (this.isResponseHandlerEnabled(response.config.params)) {
      // code here
    }
    return response;
  }

  protected respErrorHandler(error: AxiosError): any {
    if (this.isResponseHandlerEnabled(error.config.params)) {
      // code here
      if (error.response && error.response.status === 401) {
        store.dispatch({
          type: SetIsAuthAction,
          value: false
        });
      }
    }
    return error;
  }

  private isRequestHandlerEnabled(params: {noIntercept?: boolean} = {}): boolean {
    return !params.noIntercept;
  }

  private isResponseHandlerEnabled(params: {noIntercept?: boolean} = {}): boolean {
    return !params.noIntercept;
  }
}
