import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { RestService } from './core/services/restService';
import Lockr from 'lockr';
import './App.scss';

const loading = () => <div className='animated fadeIn pt-3 text-center'><div className='sk-spinner sk-spinner-pulse'></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const Login = React.lazy(() => import('./components/Login/Login'));
const Page404 = React.lazy(() => import('./components/Page404'));
const Page500 = React.lazy(() => import('./components/Page500'));

class App extends Component {
  public unregister: any;
  public store: any;

  constructor(
    props: any,
    private restService: RestService
  ) {
    super(props);
    this.restService = new RestService();
  }

  public async componentDidMount() {
    /*if (!Lockr.get('hds:index')) {
      const data = await this.restService.get<any>('');
      Lockr.set('hds:index', data);
    }*/
  }

  public render() {
    return (
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
                <Route exact path='/login' render={props => <Login {...props}/>} />
                <Route exact path='/404' render={props => <Page404 {...props}/>} />
                <Route exact path='/500' render={props => <Page500 {...props}/>} />
                <Route path='/' render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
        </HashRouter>
    );
  }
}

export default App;
