import { Action } from 'redux';

export interface ITokenAction extends Action {
  value: any;
}
export interface IAuthState {
  httpToken: string;
  isAuth: boolean;
}

export const SetTokenAction = 'SetToken';
export const SetIsAuthAction = 'SetIsAuth';

const tokenReducer = (state: IAuthState = {httpToken: '', isAuth: false}, action: ITokenAction): IAuthState => {
  const _state = {...state};

  switch (action.type) {
    case SetTokenAction: {
      _state.isAuth = true;
      _state.httpToken = action.value;
      return _state;
    }
    case SetIsAuthAction: {
      _state.isAuth = action.value;
      if (action.value === false) {
        _state.httpToken = '';
      }
      return _state;
    }
    default:
      _state.httpToken = '';
      return _state;
  }
};

export default tokenReducer;
